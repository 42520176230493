import React from 'react'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import IntroHeader from '../../components/teachers/intro-header'
import IntroVideo from '../../components/teachers/intro-video'
import Starting from '../../components/teachers/starting'
import ClassesCollapses from '../../components/teachers/classes-collapses'
import Contest from '../../components/teachers/contest'
import JoinProgram from '../../components/teachers/join-program'
import ScheduleProgram from '../../components/teachers/schedule-program'
import PocketMoney from '../../components/teachers/pocket-money'
import Partners from '../../components/teachers/partners'
import Contact from '../../components/teachers/contact'
import TopScroll from '../../components/parent/top-scroll'
import LinksAnchors from '../../components/elements/links-anchors'

const IndexPageWrapper = styled.div`
  overflow: hidden;
`

const IndexPageInnerWrapper = styled.div``

const IndexPageIntroWrapper = styled.div`
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
  }
  &:before {
    background: rgba(184, 225, 210, 1);
    background: linear-gradient(
      rgba(184, 225, 210, 0.9) 0%,
      rgba(255, 255, 255, 0.8) 70%
    );
    z-index: -2;
  }
  &:after {
    background: rgb(244, 242, 247);
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 60%
    );
    z-index: -1;
  }
`

const IndexPageLinksAnchors = styled(LinksAnchors)`
  @media (max-width: 991px) {
    display: none;
  }
`

const IndexPageTopScroll = styled(TopScroll)`
  @media (min-width: 768px) {
    display: none;
  }
`

const IndexPage = ({ location }) => {
  return (
    <>
      <IndexPageWrapper>
        <IndexPageInnerWrapper>
          <SEO title="Strefa nauczyciela" />
          <Layout location={location}>
            <IndexPageIntroWrapper id="top">
              <IntroHeader
                title={
                  <>
                    Program Misja Kieszonkowe
                    <span>
                      <span>dla</span> nauczycieli
                    </span>
                  </>
                }
                links={[
                  {
                    link: '/strefa-nauczyciela/konkurs/zgloszenie-klasy',
                    name: 'Zarejestruj klasę',
                  },
                  {
                    link: '/strefa-nauczyciela/konkurs/zgloszenie-pracy',
                    name: 'Weźcie udział w konkursie',
                  },
                ]}
              />
              <IntroVideo />
            </IndexPageIntroWrapper>
            <IndexPageLinksAnchors
              list={[
                {
                  id: 'class12',
                  name: 'Scenariusz dla klas I-II',
                  offset: -150,
                },
                {
                  id: 'class3',
                  name: 'Scenariusz dla klas III',
                  offset: -150,
                },
                {
                  id: 'class46',
                  name: 'Scenariusz dla klas IV-VI',
                  offset: -150,
                },
                {
                  id: 'join',
                  name: 'Dołącz do programu',
                  offset: -150,
                },
                {
                  id: 'contest',
                  name: 'Konkurs Misja Kieszonkowe',
                  offset: -150,
                },
              ]}
              settings={{
                mobileDropdowns: false,
              }}
            />
            <ClassesCollapses />
            <JoinProgram />
            <Contest />
            <PocketMoney />
            <Partners />
            <Contact />
            <IndexPageTopScroll />
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
