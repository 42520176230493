import React from 'react'
import { Link, useStaticQuery, withPrefix, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Col } from 'reactstrap'

import ClassCollapseItemWrapper from './class-collapse-item-wrapper'
import { ButtonLink } from '../elements'

import lampGreenIcon from '../../images/lamp_green_icon.svg'
import classesBG from '../../images/teachers/classes_bg.svg'

const ClassesCollapsesWrapper = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 30px;
  padding-bottom: 60px;
  @media (max-width: 575px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 190px;
    left: 0;
    right: 76.5%;
    bottom: 0;
    display: block;
    @media (min-width: 1200px) {
      background-image: url(${classesBG});
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      right: 72.5%;
    }
  }
`

const ClassesCollapsesLeftWrapper = styled(Col)`
  display: flex;
  align-items: center;
  padding-top: 60px;
`

const ClassesCollapsesLeftInnerWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const ClassesCollapsesLeftImageWrapper = styled.div`
  display: inline-block;
  width: 70%;
  @media (max-width: 1199px) {
    width: 30%;
  }
`

const ClassesCollapsesLeftButton = styled(ButtonLink)`
  font-size: 20px;
  padding-left: 35px;
  padding-right: 35px;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
  }
  &:hover,
  &:focus {
    &:before {
      right: 10px;
      @media (min-width: 1200px) and (max-width: 1399px) {
        right: 8px;
      }
    }
  }
  &:before {
    right: 15px;
    @media (min-width: 1200px) and (max-width: 1399px) {
      right: 12px;
    }
  }
`

const ClassesCollapsesLeftLinkWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`

const ClassesCollapsesLeftLink = styled(ClassesCollapsesLeftButton)`
  background-color: transparent;
  color: ${(props) => props.theme.colorGreenLight}!important;
  &:before {
    top: 70%;
    border-left-color: ${(props) => props.theme.colorGreenLight};
    @media (min-width: 1200px) and (max-width: 1399px) {
      top: 68%;
    }
  }
`

const ClassesCollapsesLeftButtonLinkWrapper = styled.div`
  text-align: right;
  margin-top: 30px;
  @media (max-width: 1199px) {
    text-align: center;
  }
`

const ClassesCollapsesLeftButtonLink = styled(ClassesCollapsesLeftButton)``

const ClassesCollapsesContentWrapper = styled(Col)`
  font-size: 18px;
  font-weight: 300;
  padding-top: 50px;
  @media (min-width: 1200px) {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  h3 {
    color: ${(props) => props.theme.colorGreenLight};
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 25px;
  }
  b,
  strong {
    font-weight: 400;
  }
`

const ClassesCollapsesContentList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    position: relative;
    padding-left: 50px;
    margin-bottom: 25px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 30px;
      background-image: url(${lampGreenIcon});
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
    }
  }
`

const ClassesCollapses = (props) => {
  const data = useStaticQuery(graphql`
    query {
      classes12Image: file(relativePath: { eq: "teachers/classes12.png" }) {
        childImageSharp {
          fluid(maxWidth: 232, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      classes3Image: file(relativePath: { eq: "teachers/classes3.png" }) {
        childImageSharp {
          fluid(maxWidth: 218, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      classes46Image: file(relativePath: { eq: "teachers/classes46.png" }) {
        childImageSharp {
          fluid(maxWidth: 218, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ClassesCollapsesWrapper>
      <Container>
        <ClassCollapseItemWrapper
          title="Klasy I-II"
          colorLine="#91BE23"
          id="class12"
        >
          <ClassesCollapsesLeftWrapper xs={12} xl={3}>
            <ClassesCollapsesLeftInnerWrapper>
              <ClassesCollapsesLeftImageWrapper>
                <Img
                  fluid={data.classes12Image.childImageSharp.fluid}
                  className="Klasy I-II"
                />
              </ClassesCollapsesLeftImageWrapper>
              {/*<ClassesCollapsesLeftLinkWrapper>
                <ClassesCollapsesLeftLink
                  as={Link}
                  to="/strefa-nauczyciela/konkurs/zgloszenie-klasy"
                  title="Zgłoś się do programu, by otrzymać scenariusze"
                >
                  Zgłoś się do programu,
                  <br />
                  by otrzymać scenariusz
                </ClassesCollapsesLeftLink>
              </ClassesCollapsesLeftLinkWrapper>*/}
              <ClassesCollapsesLeftButtonLinkWrapper>
                <ClassesCollapsesLeftButtonLink
                  href={withPrefix('/docs/Scenariusz_lekcji_klasy_I.pdf')}
                  id="strefa-nauczyciela-przykladowy-scenariusz-I"
                  style={{ minWidth: '87%' }}
                >
                  Przykładowy scenariusz klasy I
                </ClassesCollapsesLeftButtonLink>
              </ClassesCollapsesLeftButtonLinkWrapper>
              <ClassesCollapsesLeftButtonLinkWrapper>
                <ClassesCollapsesLeftButtonLink
                  href={withPrefix('/docs/Scenariusz_lekcji_klasy_II.pdf')}
                  id="strefa-nauczyciela-przykladowy-scenariusz-II"
                  style={{ minWidth: '87%' }}
                >
                  Przykładowy scenariusz klasy II
                </ClassesCollapsesLeftButtonLink>
              </ClassesCollapsesLeftButtonLinkWrapper>
            </ClassesCollapsesLeftInnerWrapper>
          </ClassesCollapsesLeftWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>Uczysz klasę I{'\u00A0'}lub II? Skorzystaj ze{'\u00A0'}specjalnie przygotowanych dla tych grup wiekowych scenariuszy lekcji, <strong>dopasowanych do{'\u00A0'}podstawy programowej</strong>.</li>
              <li>Scenariusze zostały zróżnicowane oraz dostosowane do{'\u00A0'}umiejętności dzieci w{'\u00A0'}klasie I i{'\u00A0'}II.</li>
              <li>Materiały otrzymasz zaraz po{'\u00A0'}zarejestrowaniu się w{'\u00A0'}programie.</li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>W{'\u00A0'}tym wieku dzieci stawiają pierwsze kroki w{'\u00A0'}stronę samodzielności. To{'\u00A0'}właśnie teraz czekają je{'\u00A0'}pierwsze zakupy w{'\u00A0'}sklepiku szkolnym. To{'\u00A0'}odpowiedni czas, aby dzieci dowiedziały się skąd biorą się pieniądze, jakie mają nominały, jak je{'\u00A0'}liczyć oraz przede wszystkim jak nimi gospodarować.</li>
              <li>Dzieci zdobędą potrzebną im{'\u00A0'}wiedzę, a{'\u00A0'}następnie będą mogły wykorzystać ją{'\u00A0'}w{'\u00A0'}praktyce.</li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
        </ClassCollapseItemWrapper>

        <ClassCollapseItemWrapper
          title="Klasy I-III"
          colorLine="#C20069"
          id="class3"
        >
          <ClassesCollapsesLeftWrapper xs={12} xl={3}>
            <ClassesCollapsesLeftInnerWrapper>
              <ClassesCollapsesLeftImageWrapper>
                <Img
                  fluid={data.classes3Image.childImageSharp.fluid}
                  className="Klasy III"
                />
              </ClassesCollapsesLeftImageWrapper>
              <ClassesCollapsesLeftButtonLinkWrapper>
                <ClassesCollapsesLeftButtonLink
                  href={withPrefix('/docs/Scenariusz_lekcji_klasy_III.pdf')}
                  id="strefa-nauczyciela-przykladowy-scenariusz"
                >
                  Przykładowy scenariusz klasy III
                </ClassesCollapsesLeftButtonLink>
              </ClassesCollapsesLeftButtonLinkWrapper>
            </ClassesCollapsesLeftInnerWrapper>
          </ClassesCollapsesLeftWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>
                Jeżeli jesteś nauczycielem klasy III skorzystaj ze{'\u00A0'}
                specjalnie przygotowanych scenariuszy lekcji, uwzględniających <strong>zagadnienia wpisane w{'\u00A0'}podstawę programową</strong>.
              </li>
              <li>
                Scenariusze otrzymasz po{'\u00A0'}zarejestrowaniu się{'\u00A0'}w
                {'\u00A0'}programie.
              </li>
              <li>
                Na{'\u00A0'}pewno dobrze znasz swoich uczniów i{'\u00A0'}wiesz,
                że{'\u00A0'}jeżeli przekażesz im{'\u00A0'}samą teorię, to
                {'\u00A0'}cała nauka może „pójść w{'\u00A0'}las”. Dlatego, każdy
                scenariusz opracowany przez doświadczonych metodyków, pozwala
                dzieciom wykorzystać zdobytą wiedzę w{'\u00A0'}praktyce.
              </li>
              <li>
                Punktem wyjścia każdego scenariusza jest problem gospodarowania
                przez dzieci kieszonkowym.
              </li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>
                Zajęcia w{'\u00A0'}ramach pierwszego scenariusza uporządkują
                wiedzę dzieci na{'\u00A0'}temat tego, skąd biorą się{'\u00A0'}
                pieniądze, jak{'\u00A0'}się{'\u00A0'}je{'\u00A0'}zarabia, jak
                {'\u00A0'}wyglądają banknoty i{'\u00A0'}monety, jak{'\u00A0'}
                można odkładać kieszonkowe oraz jak{'\u00A0'}rozmieniać i
                {'\u00A0'}liczyć pieniądze.
              </li>
              <li>
                W{'\u00A0'}scenariuszu drugim zawarte są{'\u00A0'}
                aktywności dzięki, którym dzieci dowiedzą się{'\u00A0'}m.
                {'\u00A0'}in.{'\u00A0'}jak{'\u00A0'}założyć konto w{'\u00A0'}
                banku, jak{'\u00A0'}posługiwać się{'\u00A0'}kartą płatniczą, na
                {'\u00A0'}czym polegają płatności bezgotówkowe i{'\u00A0'}jak
                {'\u00A0'}zaprojektować listę zakupową
              </li>
              <li>
                Przekonają się{'\u00A0'}także, że{'\u00A0'}nie{'\u00A0'}warto
                oceniać innych przez pryzmat statusu materialnego, a{'\u00A0'}
                pieniądze mogą być środkiem do{'\u00A0'}własnego rozwoju – nie
                {'\u00A0'}tylko realizacji zachcianek.
              </li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
        </ClassCollapseItemWrapper>

        <ClassCollapseItemWrapper
          title="Klasy IV-VI"
          colorLine="#24B3C7"
          id="class46"
        >
          <ClassesCollapsesLeftWrapper xs={12} xl={3}>
            <ClassesCollapsesLeftInnerWrapper>
              <ClassesCollapsesLeftImageWrapper>
                <Img
                  fluid={data.classes46Image.childImageSharp.fluid}
                  className="Klasy IV-VI"
                />
              </ClassesCollapsesLeftImageWrapper>
              <ClassesCollapsesLeftButtonLinkWrapper>
                <ClassesCollapsesLeftButtonLink
                  href={withPrefix('/docs/Scenariusz_lekcji_klasy_IV_VI.pdf')}
                  id="strefa-nauczyciela-przykladowy-scenariusz-IV-VI"
                >
                  Przykładowy scenariusz klasy IV-VI
                </ClassesCollapsesLeftButtonLink>
              </ClassesCollapsesLeftButtonLinkWrapper>
            </ClassesCollapsesLeftInnerWrapper>
          </ClassesCollapsesLeftWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>
                Materiały dydaktyczne przygotowane dla{'\u00A0'}klas{'\u00A0'}
                IV-VI pomogą Ci{'\u00A0'}w{'\u00A0'}realizacji zajęć metodą
                projektową, <strong>która wpisana jest w{'\u00A0'}podstawę programową</strong>.
                Może to{'\u00A0'}być pomysł na{'\u00A0'}cykl wartościowych
                lekcji wychowawczych! Dzięki metodzie projektu skłonisz swoich
                uczniów do{'\u00A0'}samodzielnego i{'\u00A0'}krytycznego
                myślenia. Podniesiesz też{'\u00A0'}ich poziom motywacji do
                {'\u00A0'}zdobywania nowych informacji i{'\u00A0'}umiejętności.
              </li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
          <ClassesCollapsesContentWrapper xs={12} md={6}>
            <ClassesCollapsesContentList>
              <li>
                <h3>Drobne wskazówki</h3>
                <p>
                  Pozwól, by{'\u00A0'}dzieci same wybrały zagadnienia do{'\u00A0'}
                  projektu wokół pieniędzy i{'\u00A0'}kieszonkowego oraz określiły
                  czego chcą się{'\u00A0'}dowiedzieć podczas jego realizacji.
                  Następnie razem zaplanujcie i{'\u00A0'}zrealizujcie działania
                  pomagające zdobyć tę wiedzę.
                  <br />
                  Mogą to{'\u00A0'}być zajęcia terenowe, rozmowy z{'\u00A0'}
                  ekspertami lub{'\u00A0'}rodzicami, oglądanie filmów tematycznych,
                  czy{'\u00A0'}kontakt z{'\u00A0'}przedstawicielem banku. Na
                  {'\u00A0'}koniec zadaniem uczniów jest podsumowanie zdobytej
                  wiedzy i{'\u00A0'}umiejętności w{'\u00A0'}dowolnej formie:
                  plakatu, szkolnej wystawy, prezentacji, a{'\u00A0'}nawet
                  przedstawienia. Więcej informacji znajdziesz w{'\u00A0'}
                  materiałach przesłanych po{'\u00A0'}rejestracji w{'\u00A0'}
                  programie.
                </p>
              </li>
            </ClassesCollapsesContentList>
          </ClassesCollapsesContentWrapper>
        </ClassCollapseItemWrapper>
      </Container >
    </ClassesCollapsesWrapper >
  )
}

export default ClassesCollapses
