import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink, ButtonLinkTransparent } from '../elements'

const IntroHeaderWrapper = styled.div`
  padding-top: 50px;
  @media (max-width: 575px) {
    padding-top: 30px;
  }
`

const IntroHeaderTitleWrapper = styled(Col)``

const IntroHeaderTitle = styled.h1`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 42px;
  font-weight: 700;
  line-height: 1.1;
  @media (max-width: 575px) {
    font-size: 28px;
  }
  & > span {
    display: block;
    font-size: 28px;
    font-weight: 400;
    @media (max-width: 575px) {
      font-size: 22px;
    }
    span {
      @media (max-width: 1199px) {
        text-transform: capitalize;
      }
    }
  }
`

const IntroHeaderLinksWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`

const IntroHeaderLinksInnerWrapper = styled.div`
  @media (min-width: 1200px) {
    text-align: right;
    margin-right: -20px;
  }
  @media (max-width: 1199px) {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  @media (max-width: 575px) {
    padding-bottom: 20px;
  }
`

const IntroHeaderButtonLink = styled(ButtonLinkTransparent)`
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`

const IntroHeader = (props) => {
  const { title, links, className } = props
  return (
    <IntroHeaderWrapper className={className}>
      <Container>
        <Row>
          <IntroHeaderTitleWrapper
            xs={12}
            xl={6}
            className="introHeaderTitleWrapper"
          >
            <IntroHeaderTitle className="title">{title}</IntroHeaderTitle>
          </IntroHeaderTitleWrapper>
          <IntroHeaderLinksWrapper
            xs={12}
            xl={6}
            className="introHeaderLinksWrapper"
          >
            <IntroHeaderLinksInnerWrapper className="introHeaderLinksInnerWrapper">
              {links.map((link, idx) => {
                return (
                  <IntroHeaderButtonLink
                    as={Link}
                    key={idx}
                    to={link.link}
                    title={link.name}
                    className="introHeaderButtonLink"
                  >
                    {link.name}
                  </IntroHeaderButtonLink>
                )
              })}
            </IntroHeaderLinksInnerWrapper>
          </IntroHeaderLinksWrapper>
        </Row>
      </Container>
    </IntroHeaderWrapper>
  )
}

export default IntroHeader
