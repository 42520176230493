import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import {
  ButtonLink,
  QuoteLineListWrapper,
  QuoteLineListItem,
  QuoteLineListItemInnerWrapper,
  QuoteLineListTitle,
} from '../elements'

const StartingWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding: 30px 0;
`

const StartingContainer = styled(Container)`
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: 1400px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const StartingListWrapper = styled(QuoteLineListWrapper)``

const StartingListItem = styled(QuoteLineListItem)``

const StartingListItemInnerWrapper = styled(QuoteLineListItemInnerWrapper)``

const StartingListTitle = styled(QuoteLineListTitle)``

const StartingOfferWrapper = styled(Row)`
  position: relative;
  margin-top: 100px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
`

const StartingOfferImage = styled(Col)`
  /* min-height: 580px; */
`

const StartingOfferImageInnerWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
`

const StartingOfferDesc = styled(Col)`
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  @media (min-width: 1400px) {
    padding-left: 80px;
  }
  @media (min-width: 1200px) {
    padding-top: 50px;
  }
  @media (min-width: 576px) {
    padding-left: 60px;
    padding-right: 10%;
    padding-bottom: 0;
  }
`

const StartingOfferDescInnerWrapper = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StartingOfferDescTitleWrapper = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
  }
`

const StartingOfferDescTextWrapper = styled.div`
  @media (max-width: 1199px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  ul {
    @media (max-width: 1199px) {
      font-weight: 700;
    }
  }
`

const StartingOfferDescTitle = styled.h2`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 56px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 46px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 36px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
  }
  br {
    @media (min-width: 1200px) {
      display: none;
    }
  }
`

const Starting = (props) => {
  const data = useStaticQuery(graphql`
    query {
      startingImage: file(relativePath: { eq: "teachers/starting2.png" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StartingWrapper id="contest">
      <StartingContainer>
        <StartingListWrapper>
          <StartingListItem xs={12}>
            <StartingListItemInnerWrapper>
              <StartingListTitle>
                <strong>Weź udział w{'\u00A0'}konkursie</strong>
              </StartingListTitle>
              <p>
                Gdy Twoi uczniowie będą już bogatsi o{'\u00A0'}cenną wiedzę zdobytą w{'\u00A0'}czasie zajęć, weźcie udział w{'\u00A0'}konkursie edukacyjnym, w{'\u00A0'}którym macie szansę wygrać wyjątkowe nagrody.
              </p>
              <ButtonLink
                as={Link}
                to="/strefa-nauczyciela/konkurs"
              >
                Poznaj szczegóły konkursu
              </ButtonLink>
            </StartingListItemInnerWrapper>
          </StartingListItem>
        </StartingListWrapper>

      </StartingContainer>
    </StartingWrapper>
  )
}

export default Starting
