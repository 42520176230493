import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import {
  QuoteLineListWrapper,
  QuoteLineListItem,
  QuoteLineListItemInnerWrapper,
  QuoteLineListTitle,
} from '../elements'

const StartingWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 30px;
`

const StartingContainer = styled(Container)`
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: 1400px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const StartingListWrapper = styled(QuoteLineListWrapper)``

const StartingListItem = styled(QuoteLineListItem)``

const StartingListItemInnerWrapper = styled(QuoteLineListItemInnerWrapper)``

const StartingListTitle = styled(QuoteLineListTitle)``

const StartingOfferWrapper = styled(Row)`
  position: relative;
  margin-top: 100px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
`

const StartingOfferImage = styled(Col)`
  /* min-height: 580px; */
`

const StartingOfferImageInnerWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
`

const StartingOfferDesc = styled(Col)`
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  @media (min-width: 1400px) {
    padding-left: 80px;
  }
  @media (min-width: 1200px) {
    padding-top: 50px;
  }
  @media (min-width: 576px) {
    padding-left: 60px;
    padding-right: 10%;
    padding-bottom: 0;
  }
`

const StartingOfferDescInnerWrapper = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StartingOfferDescTitleWrapper = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
  }
`

const StartingOfferDescTextWrapper = styled.div`
  @media (max-width: 1199px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  ul {
    @media (max-width: 1199px) {
      font-weight: 700;
    }
  }
`

const StartingOfferDescTitle = styled.h2`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 56px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 46px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 36px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
  }
  br {
    @media (min-width: 1200px) {
      display: none;
    }
  }
`

const Starting = (props) => {
  const data = useStaticQuery(graphql`
    query {
      startingImage: file(relativePath: { eq: "teachers/starting2.png" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StartingWrapper>
      <StartingContainer>
        <StartingListWrapper>
          <StartingListItem xs={12} md={6}>
            <StartingListItemInnerWrapper>
              <StartingListTitle>
                <strong>
                  Warto pogadać w{'\u00A0'}klasie o{'\u00A0'}kasie
                </strong>
              </StartingListTitle>
              <p>
                Nauczycielu, chcesz uświadomić swoim uczniom, że{'\u00A0'}
                pieniądze nie{'\u00A0'}rosną na{'\u00A0'}drzewie?
                <br />
                Dołącz do{'\u00A0'}programu edukacyjnego, którego inicjatorem
                jest Bank BNP Paribas.
              </p>
            </StartingListItemInnerWrapper>
          </StartingListItem>
          <StartingListItem xs={12} md={6}>
            <StartingListItemInnerWrapper>
              <StartingListTitle>
                <strong>Czas to{'\u00A0'}pieniądz!</strong>
              </StartingListTitle>
              <p>
                Zaoszczędź swój czas. Skorzystaj z{'\u00A0'}gotowych materiałów
                dydaktycznych przygotowanych przez metodyków wpisanych w
                {'\u00A0'}podstawę programową. Poznaj zupełnie nowe podejście do
                {'\u00A0'}nauczania o{'\u00A0'}pieniądzu! Materiały oraz zadania
                konkursowe w ramach programu dopasowane są{'\u00A0'}do{'\u00A0'}
                dwóch grup wiekowych: uczniów klas III oraz klas IV-VI.
              </p>
            </StartingListItemInnerWrapper>
          </StartingListItem>
        </StartingListWrapper>
        <StartingOfferWrapper>
          <StartingOfferImage xs={12} md={6} xl={6}>
            <Img
              fluid={data.startingImage.childImageSharp.fluid}
              alt="Pakiet startowy dla 500 pierwszych klas!"
            />
          </StartingOfferImage>
          <StartingOfferDesc xs={12} md={6} xl={6}>
            <StartingOfferDescInnerWrapper>
              <StartingOfferDescTitleWrapper>
                <StartingOfferDescTitle>
                  Pakiet
                  <br /> startowy
                  <br /> dla 500
                  <br /> pierwszych
                  <br /> klas!
                </StartingOfferDescTitle>
              </StartingOfferDescTitleWrapper>
              <StartingOfferDescTextWrapper>
                <p>
                  Pakiet startowy dla 500 pierwszych klas.
                  <br />
                  250 klas III oraz 250 klas IV-VI, których nauczyciele zgłoszą
                  chęć udziału w{'\u00A0'}programie jako pierwsi, otrzyma
                  przesyłkę z{'\u00A0'}pakietem startowym z{'\u00A0'}
                  atrakcyjnymi materiałami dydaktycznymi. Narzędzia te{'\u00A0'}
                  pomogą dzieciom poszerzyć <br />i{'\u00A0'}utrwalić wiedzę
                  zdobytą w{'\u00A0'}
                  czasie zajęć.
                </p>
                <ul>
                  <li>gra edukacyjna Samodzielniak Finansowy</li>
                  <li>plakat dydaktyczny</li>
                </ul>
              </StartingOfferDescTextWrapper>
            </StartingOfferDescInnerWrapper>
          </StartingOfferDesc>
        </StartingOfferWrapper>
      </StartingContainer>
    </StartingWrapper>
  )
}

export default Starting
