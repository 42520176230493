import React from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'

const PocketMoneyWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colorGreen};
  color: ${(props) => props.theme.colorWhite};
  padding-top: 40px;
  padding-bottom: 50px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    display: block;
    background-color: #91bd24;
    @media (min-width: 1200px) {
      content: '';
    }
  }
`

const PocketMoneyContainer = styled(Container)`
  position: relative;
  z-index: 5;
`

const PocketMoneyTitleWrapper = styled(Col)``

const PocketMoneyTitle = styled.h2`
  display: inline-block;
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 60px;
  & > span {
    position: relative;
    display: block;
    text-transform: lowercase;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 70%;
      bottom: -33px;
      display: block;
      height: 3px;
      background-color: #91bd24;
    }
    span {
      color: #91bd24;
    }
  }
`

const PocketMoneyDescWrapper = styled(Col)`
  @media (min-width: 1200px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
`

const PocketMoneyDescInnerWrapper = styled(Col)`
  font-size: 18px;
  font-weight: 300;
  padding-top: 30px;
  p {
    margin-bottom: 30px;
  }
  strong,
  b {
    font-weight: 700;
  }
`

const PocketMoneyImageWrapper = styled(Col)`
  @media (max-width: 1199px) {
    display: none;
  }
  @media (min-width: 992px) {
    flex: 0 0 40%;
    max-width: 40%;
  }
`

const PocketMoneyImageInnerWrapper = styled.div``

const PocketMoneyIButtonLink = styled(ButtonLink)`
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  @media (min-width: 1200px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
  @media (max-width: 414px) {
    width: 100%;
  }
  &:before {
    right: 27px;
  }
`

const PocketMoney = (props) => {
  const data = useStaticQuery(graphql`
    query {
      pocketMoneyImage: file(
        relativePath: { eq: "teachers/pocket_money.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 540) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <PocketMoneyWrapper>
      <PocketMoneyContainer>
        <Row>
          <PocketMoneyTitleWrapper xs={12}>
            <PocketMoneyTitle color="#91BD24">
              Czy dawanie kieszonkowego{' '}
              <span>
                się <span>opłaca</span>?
              </span>
            </PocketMoneyTitle>
          </PocketMoneyTitleWrapper>
          <PocketMoneyDescWrapper xs={12} xl={6}>
            <Row>
              <PocketMoneyDescInnerWrapper xs={12} lg={6}>
                <p>
                  <strong>
                    Uczenie dzieci o{'\u00A0'}pieniądzach jest potrzebne – lecz
                    nie jest łatwe!
                  </strong>
                </p>
                <p>
                  Budżet, finanse, zarobki, oszczędności – to{'\u00A0'}wiele
                  określeń abstrakcyjnych dla dziecka. Dla Twoich uczniów
                  pieniądze to{'\u00A0'}po prostu - kieszonkowe. Jak pokazują
                  badania przeprowadzone przez Bank BNP Paribas w{'\u00A0'}
                  Polsce aż 80% rodziców deklaruje, że daje swoim dzieciom
                  kieszonkowe, ale aż{'\u00A0'}50% opiekunów liczy na{'\u00A0'}
                  wsparcie ze{'\u00A0'}strony szkoły w{'\u00A0'}edukacji dzieci
                  na{'\u00A0'}temat odpowiedzialnego gospodarowania nim.
                </p>
              </PocketMoneyDescInnerWrapper>
              <PocketMoneyDescInnerWrapper xs={12} lg={6}>
                <p>
                  Na pewno zauważasz każdego dnia, że dzieci dysponują
                  niewystarczającą wiedzą o{'\u00A0'}pieniądzach, co{'\u00A0'}
                  przekłada się na{'\u00A0'}np. impulsywne zakupy,
                  nieumiejętność planowania wydatków i{'\u00A0'}oszczędzania,
                  brak szacunku do{'\u00A0'}pieniądza, brak świadomości wartości
                  posiadanych pieniędzy etc. A{'\u00A0'}to z{'\u00A0'}kolei
                  wpływa na{'\u00A0'}potencjalne problemy w{'\u00A0'}dorosłym
                  życiu.
                </p>
                <p>
                  Dzieci, bez względu na{'\u00A0'}status ekonomiczny, potrzebują
                  wyposażenia w{'\u00A0'}wiedzę pomagającą im{'\u00A0'}
                  samodzielnie zarządzać własnym budżetem. Jako nauczyciel masz
                  okazje i{'\u00A0'}powody, by{'\u00A0'}poruszyć z{'\u00A0'}
                  dziećmi temat kieszonkowego i{'\u00A0'}właściwego nim
                  gospodarowania.
                </p>
                <p>
                  <strong>
                    Nasze scenariusze zajęć Ci{'\u00A0'}w{'\u00A0'}tym pomogą!
                  </strong>
                </p>
                <PocketMoneyIButtonLink
                  as={Link}
                  to="/strefa-rodzica/kieszonkowe"
                  title="Rekomendowana wysokość kieszonkowego"
                >
                  Sprawdź wysokość kieszonkowego
                </PocketMoneyIButtonLink>
              </PocketMoneyDescInnerWrapper>
            </Row>
          </PocketMoneyDescWrapper>
          <PocketMoneyImageWrapper xs={12} md={6}>
            <PocketMoneyImageInnerWrapper>
              <Img
                fluid={data.pocketMoneyImage.childImageSharp.fluid}
                alt="Czy dawanie kieszonkowego się opłaca?"
              />
            </PocketMoneyImageInnerWrapper>
          </PocketMoneyImageWrapper>
        </Row>
      </PocketMoneyContainer>
    </PocketMoneyWrapper>
  )
}

export default PocketMoney
