import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player/youtube'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import playIcon from '../../images/play_icon.svg'

const IntroVideoWrapper = styled.div``

const IntroVideoInnerWrapper = styled.div`
  position: relative;
`

const IntroVideoPlaceholerWrapper = styled.div`
  position: relative;
  z-index: 5;
`

const IntroVideoPlaceholerInnerWrapper = styled.div`
  position: relative;
`

const IntroVideoPlaceholerImageWrapper = styled.div`
  @media (min-width: 991px) {
    width: 65%;
  }
`

const IntroVideoContentWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 6;
  @media (min-width: 992px) {
    position: absolute;
    padding-left: 70%;
    text-align: right;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    @media (max-width: 1199px) {
      font-size: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    strong {
      font-size: 20px;
      font-weight: 700;
      @media (max-width: 1199px) {
        font-size: 17px;
      }
    }
  }
`

const IntroVideoContentInnerWrapper = styled.div`
  color: ${(props) => props.theme.colorGreenLight};
`

const IntroVideoContenttitle = styled.h2`
  font-size: 42px;
  font-weight: 400;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
  }
  @media (max-width: 991px) {
    color: ${(props) => props.theme.colorGreenLight};
    position: relative;
    padding-top: 30px;
    z-index: 20;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    color: ${(props) => props.theme.colorGreenLight};
    font-size: 28px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
  span {
    display: block;
    font-size: 76px;
    font-weight: 700;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 66px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 60px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 50px;
    }
    @media (max-width: 575px) {
      font-size: 24px;
    }
    @media (max-width: 340px) {
    }
  }
`

const IntroVideoPlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-image: url(${playIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
  opacity: 1;
  z-index: 8;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 575px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 340px) {
    width: 30px;
    height: 30px;
  }
  &:hover {
    opacity: 0.9;
  }
`

const IntroVideoPlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 10;
  ${(props) =>
    props.play &&
    `
    visibility: visible;
  `}
`

const IntroVideo = (props) => {
  const VIDEO_URL = `https://www.youtube.com/watch?v=lgQOfO-EZD8`

  const data = useStaticQuery(graphql`
    query {
      introVideoPlaceholder: file(
        relativePath: { eq: "teachers/intro_video_placeholder2-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1364, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [playVideo, setPlayVideo] = useState(false)

  const handlePlayVideo = () => {
    setPlayVideo(!playVideo)
  }

  return (
    <IntroVideoWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <IntroVideoInnerWrapper>
              <IntroVideoPlaceholerWrapper>
                <IntroVideoPlaceholerInnerWrapper>
                  <IntroVideoPlaceholerImageWrapper play={playVideo}>
                    <Img
                      fluid={data.introVideoPlaceholder.childImageSharp.fluid}
                      alt="Pieniądze szczęścia nie dają?"
                    />
                  </IntroVideoPlaceholerImageWrapper>
                  {/*<IntroVideoPlayButton onClick={() => handlePlayVideo()} />*/}
                  <IntroVideoPlayerWrapper play={playVideo}>
                    <ReactPlayer
                      playing={playVideo}
                      controls
                      url={VIDEO_URL}
                      height="100%"
                      width="100%"
                      onEnded={handlePlayVideo}
                    />
                  </IntroVideoPlayerWrapper>
                </IntroVideoPlaceholerInnerWrapper>
                <IntroVideoContentWrapper>
                  <IntroVideoContentInnerWrapper>
                    <IntroVideoContenttitle>
                      Zacznijmy wartościowe <span>rozmowy</span>
                    </IntroVideoContenttitle>
                    <p>
                      Dziecko mające możliwość praktykowania na{'\u00A0'}własnym
                      kieszonkowym może budować cenne nawyki, zdobywać wiedzę i
                      {'\u00A0'}samodzielność finansową – wszystko, co{'\u00A0'}
                      przydatne w{'\u00A0'}dorosłym życiu.
                    </p>
                  </IntroVideoContentInnerWrapper>
                </IntroVideoContentWrapper>
              </IntroVideoPlaceholerWrapper>
            </IntroVideoInnerWrapper>
          </Col>
        </Row>
      </Container>
    </IntroVideoWrapper>
  )
}

export default IntroVideo
