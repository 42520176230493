import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Collapse } from 'reactstrap'

import { TitleWithLine } from '../elements'

const ClassCollapseItemWrapperWrapper = styled(Row)`
  @media (min-width: 576px) {
    padding-top: 30px;
  }
`

const ClassCollapseItemWrapperTitle = styled(TitleWithLine)`
  margin-bottom: 60px;
  @media (min-width: 992px) {
    pointer-events: none;
  }
  @media (max-width: 991px) {
    cursor: pointer;
  }
`

const ClassCollapseItemWrapperCollapse = styled(Collapse)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 992px) {
    display: flex !important;
  }
`

const ClassCollapseItemWrapper = (props) => {
  const { title, colorLine, children } = props

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <ClassCollapseItemWrapperWrapper id={props.id}>
      <Col xs={12}>
        <ClassCollapseItemWrapperTitle color={colorLine} onClick={toggle}>
          <span>{title}</span>
        </ClassCollapseItemWrapperTitle>
      </Col>
      <ClassCollapseItemWrapperCollapse isOpen={isOpen}>
        {children}
      </ClassCollapseItemWrapperCollapse>
    </ClassCollapseItemWrapperWrapper>
  )
}

export default ClassCollapseItemWrapper
