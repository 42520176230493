import React from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, ButtonLink } from '../elements'

const JoinProgramWrapper = styled.div`
  padding-bottom: 80px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`

const JoinProgramContainer = styled(Container)``

const JoinProgramTitle = styled(TitleWithLine)``

const JoinProgramRowWrapper = styled(Row)`
  justify-content: center;
  padding-left: 8%;
  padding-right: 8%;
`

const JoinProgramItem = styled(Col)`
  position: relative;
  text-align: center;
  padding-left: 2.4%;
  padding-right: 2.4%;
  padding-bottom: 55px;
  margin-bottom: 20px;
`

const JoinProgramItemImageWrapper = styled.div`
  margin-bottom: 30px;
`

const JoinProgramItemDescWrapper = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding-left: 7.5%;
  padding-right: 7.5%;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
`

const JoinProgramItemTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
  }
`

const JoinProgramItemButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

const JoinProgramItemButton = styled(ButtonLink)``

const JoinProgram = (props) => {
  const data = useStaticQuery(graphql`
    query {
      joinProgramImage1: file(
        relativePath: { eq: "teachers/join_program1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      joinProgramImage2: file(
        relativePath: { eq: "teachers/join_program2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      joinProgramImage3: file(
        relativePath: { eq: "teachers/join_program3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <JoinProgramWrapper>
      <JoinProgramContainer>
        <Row>
          <Col>
            <JoinProgramTitle color="#91BD24" id="join">
              <span>Dołącz do programu</span>
            </JoinProgramTitle>
          </Col>
        </Row>
        <JoinProgramRowWrapper>
          <JoinProgramItem number={1} xs={12} md={6} xl={4}>
            <JoinProgramItemImageWrapper>
              <Img
                fluid={data.joinProgramImage1.childImageSharp.fluid}
                alt="Zgłoś swoją klasę do programu"
              />
            </JoinProgramItemImageWrapper>
            <JoinProgramItemDescWrapper>
              <JoinProgramItemTitle>
                Zgłoś swoją klasę do{'\u00A0'}programu
              </JoinProgramItemTitle>
            </JoinProgramItemDescWrapper>
            <JoinProgramItemButtonWrapper>
              <JoinProgramItemButton
                as={Link}
                to="/strefa-nauczyciela/konkurs/zgloszenie-klasy"
                title="Dołącz do programu"
              >
                Dołącz do{'\u00A0'}programu
              </JoinProgramItemButton>
            </JoinProgramItemButtonWrapper>
          </JoinProgramItem>
          <JoinProgramItem number={2} xs={12} md={6} xl={4}>
            <JoinProgramItemImageWrapper>
              <Img
                fluid={data.joinProgramImage2.childImageSharp.fluid}
                alt="Odbierz atrakcyjne materiały dydaktyczne"
              />
            </JoinProgramItemImageWrapper>
            <JoinProgramItemDescWrapper>
              <JoinProgramItemTitle>
                Odbierz atrakcyjne materiały dydaktyczne
              </JoinProgramItemTitle>
            </JoinProgramItemDescWrapper>
          </JoinProgramItem>
          <JoinProgramItem number={3} xs={12} md={6} xl={4}>
            <JoinProgramItemImageWrapper>
              <Img
                fluid={data.joinProgramImage3.childImageSharp.fluid}
                alt="Wystartuj z klasą w konkursie!"
              />
            </JoinProgramItemImageWrapper>
            <JoinProgramItemDescWrapper>
              <JoinProgramItemTitle>
                Zdobywajcie nową i{'\u00A0'}praktyczną wiedzę
              </JoinProgramItemTitle>
            </JoinProgramItemDescWrapper>
            <JoinProgramItemButtonWrapper>
              <JoinProgramItemButton
                as={Link}
                to="/strefa-nauczyciela/konkurs/zgloszenie-pracy"
                title="Weź udział w konkursie"
              >
                Weź{'\u00A0'}udział w{'\u00A0'}konkursie
              </JoinProgramItemButton>
            </JoinProgramItemButtonWrapper>
          </JoinProgramItem>
        </JoinProgramRowWrapper>
      </JoinProgramContainer>
    </JoinProgramWrapper>
  )
}

export default JoinProgram
